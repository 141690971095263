import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    this.setState(
      { active: !this.state.active },
      () => {
        this.state.active
          ? this.setState({ navBarActiveClass: 'is-active' })
          : this.setState({ navBarActiveClass: '' })
      }
    )
  }

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="logo">
            <StaticImage src="../images/logo.png" alt="logo" width={120} style={{ backgroundColor :"white"}} />
          </Link>
          <button
            onClick={this.toggleHamburger}
            className={`navbar-burger ${this.state.navBarActiveClass}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div id="navbarMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
          <div className="navbar-start">
            <AnchorLink to="/#illustrations" className="navbar-item" title="Illustrations" onAnchorLinkClick={this.toggleHamburger} stripHash />
            <AnchorLink to="/#about" className="navbar-item" title="About" onAnchorLinkClick={this.toggleHamburger} stripHash />
            <AnchorLink to="/#request" className="navbar-item" title="Request" onAnchorLinkClick={this.toggleHamburger} stripHash />
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
