import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  return (
    <section className="hero is-large">
      <div className="hero-body has-hero-image is-relative">
        <div className="hero-image">
          <StaticImage src="../images/hero.jpg" alt="hero" className="hero-image-wrapper" />
        </div>
      </div>
    </section>
  )
}

export default Header
