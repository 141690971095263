import React from 'react'
import Helmet from 'react-helmet'
import { useSiteMetadata } from "../hooks/use-site-metadata"

const HelmetComponent = ({ isWebsite, noIndex, location }) => {
  const { siteMetadata } = useSiteMetadata()
  const imageUrl = `https://www.olugho.com/assets/main.png`

  return (
    <Helmet>
      <html lang={siteMetadata.lang} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {noIndex && <meta name="googlebot" content="noindex" />}

      {isWebsite && <meta name="twitter:card" content="summary" />}
      {isWebsite && <meta property="og:type" content="website" />}
      {isWebsite && <meta property="og:title" content={siteMetadata.title} />}
      {isWebsite && <meta property="og:image" content={imageUrl} />}
      {isWebsite && <meta property="og:description" content={siteMetadata.description} />}
      {isWebsite && <meta property="og:url" content={location.href} />}
      {isWebsite && <meta property="og:site_name" content={siteMetadata.title} />}
      {isWebsite && <meta property="og:locale" content="ja_JP" />}
    </Helmet>
  )
}

export default HelmetComponent
