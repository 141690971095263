import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';
import Navbar from './navbar';

const Layout = ({ isRoot, isWebsite, noIndex, location, children }) => (
  <div>
    <Helmet isWebsite={isWebsite} noIndex={noIndex} location={location}/>
    <Navbar />
    {isRoot && <Header/> }
    <div className="container main-area px-4">{children}</div>
    <Footer />
  </div>
);

export default Layout;
