import React from 'react'
import { Link } from "gatsby"

const Footer = () => (
  <footer className="footer">
    <div className="columns is-centered is-gapless">
      <div className="column has-text-centered is-3">
        <Link to="/privacy">プライバシーポリシー</Link>
      </div>
      <div className="column has-text-centered is-3">
        <a href="https://forms.gle/Fde9F6YbyqpazwDp8" target="_blank" rel="nofollow noopener noreferrer">お問い合わせ</a>
      </div>
    </div>
    <div className="content has-text-centered">
      <p>本サイト内のすべてのコンテンツの使用/転載は禁止です</p>
      <p className="copyright">&copy; 2021 olugho</p>
    </div>
  </footer>
)

export default Footer
